:root {
  --dark-gray: #1f272c;
  --dark-blue: #123456;
  --red: #d7263d;
  --green: #00d35b;
  --teal: #0e7c7b;
  --purple: #b535e8;
  --shadow-box: 0 3px 4px 0 rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.14),0 2px 10px -1px rgba(0,0,0,0.12);
  counter-reset: hashtags;
}

* {
  box-sizing: border-box;
}

body {
  color: #fff;
  background: #00acee;
  background: radial-gradient(ellipse at center, #00cccc 0%, #00acee 100%);
  font-family: "Segoe UI", "Helvetica Neue", sans-serif;
  height: 100%;
  margin: 0;
}

header {
  background: var(--dark-blue);
  height: 100px;
  line-height: 100px;
}

h1 {
  font-size: 36px;
}

main {
  align-items: center;
  display: flex;
  min-height: calc(100% - 100px);
  justify-content: center;
  transition: opacity .5s;
  text-align: center;
}

main.is-switching {
  opacity: 0;
}

section {
  width: 100%;
}

h1 {
  margin: 0;
  padding: 0 20px;
}

h2 {
  font-size: 48px;
  margin: 0 auto 40px auto;
}

p {
  color: #fff;
  font-size: 28px;
  margin-bottom: 20px;
  line-height: 1.5;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.top-hashtags-item {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  color: var(--dark-gray);
  font-size: 36px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  counter-increment: hashtags;
}

.top-hashtags-item:hover {
  box-shadow: var(--shadow-box);
}

li:not(:last-child) .top-hashtags-item {
  margin-bottom: 20px;
}

.top-hashtags-value {
  font-weight: bold;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-hashtags-rank {
  color: #666;
  font-size: 18px;
  margin-right: 20px;
}

.top-hashtags-rank::before {
  content: counter(hashtags);
}

.note {
  color: #666;
  font-size: 18px;
  margin-left: 20px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: .2s;
}

a:hover {
  color: #00acee;
}

#logo {
  width: 300px;
  vertical-align: middle;
}

#handle-input {
  border: 10px solid var(--dark-blue);
  border-radius: 20px;
  font-size: 36px;
  padding: 10px;
  text-align: center;
  width: 90%;
  max-width: 720px;
  color: var(--dark-blue);
  font-weight: bold;
}

#results {
  text-align: center;
}

#results h2 {
  font-size: 36px;
}

#most-used-hashtag {
  background: #fff;
  background-image: url(images/sparkle.gif);
  color: var(--dark-gray);
  overflow: hidden;
  text-overflow: ellipsis;
}

.most-used-hashtag-header {
  color: var(--dark-gray);
}

.most-used-hashtag {
  font-size: 72px;
  font-weight: bold;
  word-wrap: break-word;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

#results .col-2 {
  width: 40%;
  margin: 0 5%;
  float: left;
}

#top-ten-hashtags-used .rank {
  float: left;
  margin-right: 10px;
}

#top-ten-hashtags-used .times {
  float: right;
  color: #ccc;
  font-size: 28px;
  margin-left: 10px;
}

#swag-check {
  background-color: var(--green);
}

#swag-check .result {
  font-size: 24px;
}

#totals {
  background-color: var(--teal);
}

.total {
  font-weight: bold;
}

#again {
  background-color: var(--purple);
}

.content-container {
  margin: auto;
  max-width: 720px;
  padding: 40px;
  width: 100%;
}

.swag-check-icon {
  display: block;
  filter: brightness(1000%);
  height: 50px;
  margin: 0 auto 40px;
  width: 50px;
}

#share {
  background-color: var(--red);
}

.share-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.share-buttons > * {
  margin: 0 10px 20px 10px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  color: #333;
  transition: .3s;
  background-color: #fff;
  font-size: 20px;
}

.button:active {
  position: relative;
  top: 2px;
}

.button:hover {
  box-shadow: var(--shadow-box);
}

@media (max-width: 720px) {

  .content-container {
    padding: 20px;
  }

  h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  #handle-input {
    font-size: 28px;
  }

  .most-used-hashtag {
    font-size: 36px;
  }

  .note {
    font-size: 16px;
  }

  .top-hashtags-item {
    border-radius: 10px;
    font-size: 28px;
    padding: 10px;
    text-align: center;
  }

  .top-hashtags-rank {
    display: none;
  }

  .top-hashtags-value {
    font-size: 28px;
    margin-bottom: 5px;
  }

  .top-hashtags-count {
    margin-left: 0;
    width: 100%;
  }

  #top-ten-hashtags-used .rank,
  #top-ten-hashtags-used .times {
    display: block;
    float: none;
    margin-bottom: 10px;
  }

  #results h2,
  #most-used-hashtag .result {
    font-size: 28px;
  }

  .content {
    word-wrap: break-word;
    padding: 20px 0;
  }

  .swag-check-icon {
    margin-bottom: 20px;
  }
}

@media (max-width: 400px) {

  header {
    height: 80px;
    line-height: 80px;
    text-align: center;
  }

  h2 {
    font-size: 28px;
  }

  #handle-input {
    border-width: 5px;
    font-size: 18px;
  }

  .top-hashtags-value {
    font-size: 18px;
    margin-bottom: 5px;
  }

  p,
  .button {
    font-size: 18px;
  }
}
